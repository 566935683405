import { FC } from "react"
import { Radio, Input, Form, Checkbox } from "antd"
import { ReactComponent as CloseIcon } from "../../../images/icons/close.svg"
import { useGeneralContext } from "../../context"
import "./index.css"

const StrategiesInterests: FC = () => {
    const {
      toolsAndStrats,
      setToolsAndStrats,
      optimizeAreas,
      setOptimizeAreas,
      additionalArea,
      setAdditionalArea,
      concerns,
      setConcerns,
      } = useGeneralContext()

      return (
        <div>
          <div>
            <b>What tools or strategies are you currently using?</b>
            <textarea value={toolsAndStrats} placeholder={"Message"} onChange={e => setToolsAndStrats(e.target.value)} />
          </div>

          <div className="form--item">
          <b>What areas are you most interested in optimizing? (Check all that apply)</b>
            <Checkbox.Group value={optimizeAreas} onChange={e => setOptimizeAreas(e)}>
              <Checkbox value="storage">
                Storage (S3, Glacier)
              </Checkbox>
              <Checkbox value="network services">
                Network services
              </Checkbox>
              <Checkbox value="database services">
                Database services (RDS, DynamoDB)
              </Checkbox>
              <Checkbox value="application services">
              Application services (Lambda, Elastic Beanstalk)
              </Checkbox>
              <Checkbox value="other">
                  Other
              </Checkbox>
            </Checkbox.Group>
            <div>
            <p>Please specify additional areas:</p>
            <input className="form--item" value={additionalArea} placeholder={""} type={"text"} onChange={e => setAdditionalArea(e.target.value)}/>
          </div> 
            </div>
            
          <div className="form--item">
            <b>Do you have any specific concerns or constraints we should be aware of?</b>
            <textarea value={concerns} placeholder={"Message"} onChange={e => setConcerns(e.target.value)}/>
            <p style={{ color: "red" }}></p>
          </div>
        </div>

        
      );
}

export default StrategiesInterests;