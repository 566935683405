import emailjs from "@emailjs/browser"
import { Layout, Modal } from "antd"
import { FC, Fragment, Suspense, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { PUBLIC_KEY, SERVICE_ID, CONTACT_TEMPLATE_ID } from "../../constants"
import { ReactComponent as Arrow } from "../../images/icons/arrow.svg"
import { ReactComponent as CloseIcon } from "../../images/icons/close.svg"
import { useGeneralContext } from "../context"
import ContactForm from "../section/ContactForm"
import { Footer } from "./Footer/Footer"
import { Header } from "./Header"
import { AWSLayout} from "./AWSLayout"

import "../../styles/modal.css"

const ModalComponent = () => {
  const {
    setIsLastPage,
    isModalOpen,
    setIsAWSModalOpen,
    setIsModalOpen,
    comment,
    setCommentErr,
    setIsSuccess,
    firstName,
    setError,
    lastName,
    setLastNameErr,
    jobTitle,
    setJobErr,
    email,
    setMailErr,
    phoneNumber,
    pgNum,
    setPhoneNumberErr,
    company,
    setCompanyNameErr,
    setFirstName,
    setLastName,
    setJobTitle,
    setEmail,
    setCompany,
    setPhoneNumber,
    setComment,
    setPgNum,
  } = useGeneralContext()
  const handleCancel = () => {
    setPgNum(0)
    setIsModalOpen(false)
    setIsAWSModalOpen(false)
    setIsLastPage(false)
    setError("")
    setLastNameErr("")
    setJobErr("")
    setMailErr("")
    setCompanyNameErr("")
    setPhoneNumberErr("")
    setFirstName("")
    setLastName("")
    setJobTitle("")
    setEmail("")
    setCompany("")
    setPhoneNumber("")
    setComment("")
    setCommentErr("")
  }
  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  
  const handleSend = () => {
    if (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      jobTitle.trim() !== "" &&
      email.trim() !== "" &&
      isEmailValid(email.trim()) &&
      company.trim() !== "" &&
      phoneNumber.trim() !== "" &&
      comment.trim() !== ""
    ) {
      setIsModalOpen(false)
      setIsLastPage(false)
      setIsSuccess(true)
      emailjs.send(SERVICE_ID, CONTACT_TEMPLATE_ID, { firstName, lastName, jobTitle, email, company, phoneNumber, comment }, PUBLIC_KEY)
      handleCancel()
    }
    if (firstName.trim() === "") {
      setError("This field must not be empty")
    }
    if (lastName.trim() === "") {
      setLastNameErr("This field must not be empty")
    }
    if (jobTitle.trim() === "") {
      setJobErr("This field must not be empty")
    }
    if (email.trim() === "") {
      setMailErr("This field must not be empty")
    }
    if (!isEmailValid(email.trim())) {
      setMailErr("Invalid email address")
    }
    if (company.trim() === "") {
      setCompanyNameErr("This field must not be empty")
    }
    if (phoneNumber.trim() === ""){
      setPhoneNumberErr("This field must not be empty")
    }
    if (comment.trim() === "") {
      setCommentErr("This field must not be empty")
    }
  }

  useEffect(() => {
    if (firstName.trim() !== "") {
      setError("")
    }
    if (lastName.trim() !== "") {
      setLastNameErr("")
    }
    if (jobTitle.trim() !== "") {
      setJobErr("")
    }
    if (email.trim() !== "") {
      setMailErr("")
    }
    if (company.trim() !== "") {
      setCompanyNameErr("")
    }
    if (phoneNumber.trim() !== "") {
      setPhoneNumberErr("")
    }
    if (comment.trim() !== "") {
      setCommentErr("")
    }
  }, [firstName, lastName, jobTitle, email, company, phoneNumber, comment])

  return (
    <Modal
      title={"Contact Us"}
      centered={false}
      open={isModalOpen}
      onCancel={handleCancel}
      className={"modal-window"}
      footer= {<button className={"button hero-button"} onClick={handleSend}>
      Send <Arrow fill={"white"} />
    </button>}
      closeIcon={<CloseIcon />}
    >
    <ContactForm/>
    </Modal>
  )
}

const DefaultLayout: FC = () => (
  <Fragment>
    <AWSLayout/>
    <Layout>
      <Header />
      <Suspense>
        <Outlet />
      </Suspense>
      <Footer />
    </Layout>
    <ModalComponent />
  </Fragment>
)

export default DefaultLayout
