import { FC } from "react"
import { ReactComponent as Logo } from "../../../images/icons/logo.svg"
import { ReactComponent as Mail } from "../../../images/icons/mail.svg"
import { ReactComponent as Location } from "../../../images/icons/location.svg"
import "./index.css"

const Footer: FC = () => {
  return (
    <footer className={"footer container"}>
      <div className={"footer-wrapper"}>
        <div className={"footer-contact-info-block"}>
          <div className={"footer-logo"}>
            <Logo />
          </div>
          <div className={"footer-contact-info"}>
            <div className={"footer-contact-info-item"}>
              <span className={"contact-subtitle"}>
              <Location height={'20px'}/> 2028 E Ben White Blvd #240-9333 <br />
                Austin TX 78741<br/>
                <Mail className={"mail-icon"} style={{marginRight:"5px"}}/> contact@enstructure.net 
              </span>
            </div>
          </div>
        </div>
        <div className={"footer-info"}>
          <p className={"footer-info-text"}>enstructure © 2024 All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
