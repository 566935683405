import { ReactComponent as SuccessIcon } from "../../../images/icons/success.svg"
import "./index.css"
const SuccessNotification = () => {
  return (
    <div className={"success-notification"}>
      <p className={"notification-title"}>Your message has been sent</p>
      <SuccessIcon />
    </div>
  )
}

export default SuccessNotification
