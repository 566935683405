import { FC } from "react"
import { Modal, Radio, Input, Form } from "antd"
import { ReactComponent as CloseIcon } from "../../../images/icons/close.svg"
import { useGeneralContext } from "../../context"
import "./index.css"
import SuccessNotification from "./success"
import ClientInfo from "./ClientInfo";
import AwsInfo from "./AwsInfo";
import StrategiesInterests from "./StrategiesInterest"
import ContactPreferences from "./ContactPeferences"

const ContactForm: FC = () => {

  const {
    isModalOpen,
    isAWSModalOpen,
    isSuccess,
    setIsSuccess,
    comment,
    setComment,
    commentErr,
    pgNum,
    setPgNum,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    jobTitle,
    setJobTitle,
    company,
    setCompany,
    phoneNumber,
    setPhoneNumber,
    error,
    lastNameErr,
    jobErr,
    mailErr,
    companyNameErr,
    phoneNumberErr,
    setPhoneNumberErr,
  } = useGeneralContext()

  const renderPages = [<AwsInfo />, <StrategiesInterests/>, <ClientInfo/>, <ContactPreferences/> ]
  let currentPage = renderPages[pgNum];

  return (
    <div className={"form-wrapper"}>
      {
        !isModalOpen && isAWSModalOpen?
        (
          <form className={"form--aws"}>
        {currentPage}
        
      </form>
        ) :
        (
          <form className={"form"}>
        <div>
          <input
          value={firstName}
            style={{ position: "relative" }}
            placeholder={"First Name"}
            type={"text"}
            onChange={e => setFirstName(e.target.value)}
          />
          <p style={{ position: "absolute", color: "red" }}>{error}</p>
        </div>
        <div>
          <input value={lastName} placeholder={"Last Name"} type={"text"} onChange={e => setLastName(e.target.value)} />
          <p style={{ color: "red" }}>{lastNameErr}</p>
        </div>
        <div>
          <input value={email} placeholder={"Email"} type={"email"} onChange={e => setEmail(e.target.value)} />
          <p style={{ color: "red" }}>{mailErr}</p>
        </div>
        <div>
          <input  value={jobTitle} placeholder={"Job Title"} type={"text"} onChange={e => setJobTitle(e.target.value)} />
          <p style={{ color: "red" }}>{jobErr}</p>
        </div>
        <div>
          <input value={company} placeholder={"Company Name"} type={"text"} onChange={e => setCompany(e.target.value)} />
          <p style={{ color: "red" }}>{companyNameErr}</p>
        </div>
        <div>
        <input  value={phoneNumber} placeholder={"Phone number"} type={"text"} onChange={e => setPhoneNumber(e.target.value)} />
          <p style={{ color: "red" }}>{phoneNumberErr}</p>
          </div>
        <textarea value={comment} placeholder={"Message"} onChange={e => setComment(e.target.value)} />
        <p style={{ color: "red" }}>{commentErr}</p>
      </form>
        )

      }
      
      <Modal
        open={isSuccess}
        closable={true}
        footer={false}
        centered={false}
        onCancel={() => setIsSuccess(false)}
        closeIcon={<CloseIcon />}
        className={"notification"}
      >
        <SuccessNotification />
      </Modal>
    </div>
  )
}

export default ContactForm
