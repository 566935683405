import emailjs from "@emailjs/browser"
import { Layout, Modal } from "antd"
import { FC, Fragment, Suspense, useEffect, useState } from "react"
import { PUBLIC_KEY, SERVICE_ID, AWS_TEMPLATE_ID } from "../../../constants"
import { ReactComponent as Arrow } from "../../../images/icons/arrow.svg"
import { ReactComponent as CloseIcon } from "../../../images/icons/close.svg"
import { useGeneralContext } from "../../context"
import ContactForm from "../../section/ContactForm"
import "../../../styles/modal.css"

const AWSModalComponent = () => {
  const {
    awsMonthly,
    setAwsMonthly,
    isLastPage,
    setIsLastPage,
    isAWSModalOpen,
    setIsAWSModalOpen,
    comment,
    setCommentErr,
    setIsSuccess,
    firstName,
    setError,
    lastName,
    setLastNameErr,
    jobTitle,
    setJobErr,
    email,
    setMailErr,
    phoneNumber,
    pgNum,
    setPhoneNumberErr,
    company,
    setCompanyNameErr,
    setFirstName,
    setLastName,
    setJobTitle,
    setEmail,
    setCompany,
    setPhoneNumber,
    setComment,
    setPgNum,
    toolsAndStrats,
        numberEC2,
        otherAws,
        awsBsnsCrit,
        primaryGoal,
        otherGoal,
      concerns,
      optimizeAreas,
      additionalArea,
      preferredContactMethod,
      prefTime, 
        numberS3,
  } = useGeneralContext()
  const handleCancel = () => {
    setPgNum(0)
    setAwsMonthly("<$1,000")
    setIsAWSModalOpen(false)
    setIsLastPage(false)
    setError("")
    setLastNameErr("")
    setJobErr("")
    setMailErr("")
    setCompanyNameErr("")
    setPhoneNumberErr("")
    setFirstName("")
    setLastName("")
    setJobTitle("")
    setEmail("")
    setCompany("")
    setPhoneNumber("")
    setComment("")
    setCommentErr("")
  }
  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  /** Goes to next page on questionnaire */
  const nextPage = () => {
    if(pgNum=== 2){
      setIsLastPage(true)
    }
    if (pgNum<3){
      setPgNum(pgNum + 1);
    }
  }

  const goBack = () => {
    if (pgNum > 0){
      setPgNum(pgNum - 1);
      if (isLastPage){
        setIsLastPage(false);
      }
    }
    else {

    }
  }

  

  const handleSend = () => {
    if (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      jobTitle.trim() !== "" &&
      email.trim() !== "" &&
      isEmailValid(email.trim()) &&
      company.trim() !== "" &&
      phoneNumber.trim() !== ""
    ) {
      setIsAWSModalOpen(false)
      setIsLastPage(false)
      setIsSuccess(true)
      emailjs.send(SERVICE_ID, AWS_TEMPLATE_ID, { awsMonthly,  numberEC2,
        otherAws,
        awsBsnsCrit,
        primaryGoal,
        otherGoal,
        concerns,
        numberS3,firstName, lastName, jobTitle, email, company, comment, toolsAndStrats,
        preferredContactMethod, optimizeAreas ,additionalArea,
        prefTime }, PUBLIC_KEY)
      handleCancel()
    }
    if (firstName.trim() === "") {
      setError("This field must not be empty")
    }
    if (lastName.trim() === "") {
      setLastNameErr("This field must not be empty")
    }
    if (jobTitle.trim() === "") {
      setJobErr("This field must not be empty")
    }
    if (email.trim() === "") {
      setMailErr("This field must not be empty")
    }
    if (!isEmailValid(email.trim())) {
      setMailErr("Invalid email address")
    }
    if (company.trim() === "") {
      setCompanyNameErr("This field must not be empty")
    }
    if (phoneNumber.trim() === ""){
      setPhoneNumberErr("This field must not be empty")
    }
  }

  useEffect(() => {
    if (firstName.trim() !== "") {
      setError("")
    }
    if (lastName.trim() !== "") {
      setLastNameErr("")
    }
    if (jobTitle.trim() !== "") {
      setJobErr("")
    }
    if (email.trim() !== "") {
      setMailErr("")
    }
    if (company.trim() !== "") {
      setCompanyNameErr("")
    }
    if (phoneNumber.trim() !== "") {
      setPhoneNumberErr("")
    }
    if (comment.trim() !== "") {
      setCommentErr("")
    }
  }, [firstName, lastName, jobTitle, email, company, phoneNumber, comment])

  return (
    <Modal
      title={"Contact Us"}
      centered={false}
      open={isAWSModalOpen}
      onCancel={handleCancel}
      className={"modal-window"}
      footer= {isLastPage ? (<div style={{width: "100%", display:"flex", justifyContent: "space-between"}}><button style={{paddingLeft:'29px'}} className={"button hero-button nav--button"} onClick={goBack}>
      <Arrow fill={"white"} style={{ transform:'rotate(180deg)'}} />Go Back 
    </button>
    <button className={"button hero-button nav--button"} onClick={handleSend}>
      Send <Arrow fill={"white"} />
    </button>
      </div>
    ) : pgNum===0 ? (<div style={{width: "100%", display:"flex", justifyContent: "space-between"}}>
    <div></div><button className={"button hero-button nav--button"} onClick={nextPage}>
        Next <Arrow fill={"white"} />
      </button>
      
      </div>) : (<div style={{width: "100%", display:"flex", justifyContent: "space-between"}}>
      <button style={{paddingLeft:'25px'}} className={"button hero-button nav--button"} onClick={goBack} >
        <Arrow fill={"white"} style={{ transform:'rotate(180deg)'}} /> Go Back 
    </button>
      <button className={"button hero-button nav--button"} onClick={nextPage}>
          Next <Arrow fill={"white"} />
        </button>
        
        </div>)}
      closeIcon={<CloseIcon />}
    >
    <ContactForm/>
    </Modal>
  )
}

const AWSLayout: FC = () => (
  <Fragment>
    <AWSModalComponent />
  </Fragment>
)

export {AWSLayout}
