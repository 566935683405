import { FC } from "react"
import { Radio, Input, Form, Checkbox } from "antd"
import { ReactComponent as CloseIcon } from "../../../images/icons/close.svg"
import { useGeneralContext } from "../../context"
import "./index.css"

const ContactPreferences: FC = () => {
    const {
      preferredContactMethod,
      setPreferredContactMethod,
      prefTime, setPrefTime,
      comment, setComment, commentErr

      } = useGeneralContext()
      return (
        <div>
            <div>
          <b>Preferred Method of Communication:</b>
            <Checkbox.Group value={preferredContactMethod} onChange={e => setPreferredContactMethod(e)}>
              <Checkbox value="email">
                Email
              </Checkbox>
              <Checkbox value="phone">
                Phone Call
              </Checkbox>
              <Checkbox value="video">
                Video Conference
              </Checkbox>
              <Checkbox value="in person">
                In-person Meeting
              </Checkbox>
            </Checkbox.Group>
            </div>
            <div className="form--item">
                <b>Preferred Contact Time:</b>
                <br/>
                <Radio.Group value={prefTime} onChange={e => setPrefTime(e.target.value)}>
                    <Radio value={"8am"}>{"8 am – 12 pm CST"}</Radio>
                    <Radio value={"12pm"}>{"12 – 5 pm CST"}</Radio>
                </Radio.Group>
          </div>
          <div className="form--item">
            <b>Additional Comments or Questions: </b>
            <textarea value={comment} placeholder={"Message"} onChange={e => setComment(e.target.value)}/>
            <p style={{ color: "red" }}>{commentErr}</p>
          </div>
        </div>
      )
    }

export default ContactPreferences;