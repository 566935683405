import { FC } from "react"
import { Radio, Input, Form } from "antd"
import { ReactComponent as CloseIcon } from "../../../images/icons/close.svg"
import { useGeneralContext } from "../../context"
import "./index.css"

const ClientInfo: FC = () => {

    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        jobTitle,
        setJobTitle,
        company,
        setCompany,
        phoneNumber,
        setPhoneNumber,
        error,
        lastNameErr,
        jobErr,
        mailErr,
        companyNameErr,
        phoneNumberErr,
        setPhoneNumberErr,
      } = useGeneralContext()

    return (
        <div>
            <div>
          <input value={company} placeholder={"Company Name"} type={"text"} onChange={e => setCompany(e.target.value)} />
          <p style={{ color: "red" }}>{companyNameErr}</p>
        </div>
        <div>
          <input
          value={firstName}
            style={{ position: "relative" }}
            placeholder={"First Name"}
            type={"text"}
            onChange={e => setFirstName(e.target.value)}
          />
          <p style={{ position: "absolute", color: "red" }}>{error}</p>
        </div>
        <div>
          <input value={lastName} placeholder={"Last Name"} type={"text"} onChange={e => setLastName(e.target.value)} />
          <p style={{ color: "red" }}>{lastNameErr}</p>
        </div>
        <div>
          <input value={email} placeholder={"Email"} type={"email"} onChange={e => setEmail(e.target.value)} />
          <p style={{ color: "red" }}>{mailErr}</p>
        </div>
        <div>
          <input  value={jobTitle} placeholder={"Job Title"} type={"text"} onChange={e => setJobTitle(e.target.value)} />
          <p style={{ color: "red" }}>{jobErr}</p>
        </div>
        <div>
          <input  value={phoneNumber} placeholder={"Phone Number"} type={"text"} onChange={e => setPhoneNumber(e.target.value)} />
          <p style={{ color: "red" }}>{phoneNumberErr}</p>
        </div>
        </div>
    )
}

export default ClientInfo;

