import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from "react"

type ContextProps = {
  isAWSModalOpen: boolean
  setIsAWSModalOpen: Dispatch<SetStateAction<boolean>>
  isLastPage: boolean
  setIsLastPage: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isSuccess: boolean
  setIsSuccess: Dispatch<SetStateAction<boolean>>
  firstName: string
  setFirstName: Dispatch<SetStateAction<string>>
  lastName: string
  setLastName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  jobTitle: string
  setJobTitle: Dispatch<SetStateAction<string>>
  company: string
  setCompany: Dispatch<SetStateAction<string>>
  phoneNumber: string
  setPhoneNumber: Dispatch<SetStateAction<string>>
  comment: string
  setComment: Dispatch<SetStateAction<string>>
  commentErr:string,
  setCommentErr: Dispatch<SetStateAction<string>>
  error: string
  setError: Dispatch<SetStateAction<string>>
  lastNameErr: string
  setLastNameErr: Dispatch<SetStateAction<string>>
  jobErr: string
  setJobErr: Dispatch<SetStateAction<string>>
  mailErr: string
  setMailErr: Dispatch<SetStateAction<string>>
  companyNameErr: string
  setCompanyNameErr: Dispatch<SetStateAction<string>>
  phoneNumberErr: string
  setPhoneNumberErr: Dispatch<SetStateAction<string>>
  pgNum: number
  setPgNum: Dispatch<SetStateAction<number>>

  awsMonthly: string
  setAwsMonthly: Dispatch<SetStateAction<string>>
  numberEC2: string
  setNumberEC2: Dispatch<SetStateAction<string>>
  numberS3: string
  setNumberS3: Dispatch<SetStateAction<string>>
  otherAws:string
  setOtherAws:Dispatch<SetStateAction<string>>
  awsBsnsCrit:string
  setAwsBsnsCrit:Dispatch<SetStateAction<string>>
  primaryGoal:string[]
  setPrimaryGoal:Dispatch<SetStateAction<string[]>>
  otherGoal:string
  setOtherGoal:Dispatch<SetStateAction<string>>
  toolsAndStrats: string
  setToolsAndStrats: Dispatch<SetStateAction<string>>
  optimizeAreas: string[]
  setOptimizeAreas: Dispatch<SetStateAction<string[]>>
  additionalArea: string
  setAdditionalArea: Dispatch<SetStateAction<string>>
  concerns: string
  setConcerns: Dispatch<SetStateAction<string>>
  preferredContactMethod: string[]
  setPreferredContactMethod: Dispatch<SetStateAction<string[]>>
  prefTime: string
  setPrefTime:Dispatch<SetStateAction<string>>

  numberEC2Err: string
  setNumberEC2Err: Dispatch<SetStateAction<string>>
  numberS3Err: string
  setNumberS3Err: Dispatch<SetStateAction<string>>
  otherAwsErr: string
  setOtherAwsErr:Dispatch<SetStateAction<string>>

  
}

export const defaultValues: ContextProps = {
  isAWSModalOpen: false,
  setIsAWSModalOpen: () => null,
  isLastPage: false,
  setIsLastPage: () => null,
  isModalOpen: false,
  setIsModalOpen: () => null,
  isSuccess: false,
  setIsSuccess: () => null,
  firstName: "",
  setFirstName: () => null,
  lastName: "",
  setLastName: () => null,
  email: "",
  setEmail: () => null,
  jobTitle: "",
  setJobTitle: () => null,
  company: "",
  setCompany: () => null,
  phoneNumber: "",
  setPhoneNumber: () => null,
  comment: "",
  setComment: () => null,
  commentErr:"",
  setCommentErr: () => null,
  error: "",
  setError: () => null,
  lastNameErr: "",
  setLastNameErr: () => null,
  jobErr: "",
  setJobErr: () => null,
  mailErr: "",
  setMailErr: () => null,
  companyNameErr: "",
  setCompanyNameErr: () => null,
  phoneNumberErr: "",
  setPhoneNumberErr: () => null,
  pgNum: 0,
  setPgNum: () => null,

  awsMonthly:"<$1,000",
  setAwsMonthly: () => null,
  numberEC2:"",
  setNumberEC2:()=>null,
  numberS3: "",
  setNumberS3:()=>null,
  otherAws:"",
  setOtherAws:()=>null,
  awsBsnsCrit:"low",
  setAwsBsnsCrit:()=>null,
  primaryGoal: ["Reduce Overall Spend"],
  setPrimaryGoal:()=>null,
  otherGoal:"",
  setOtherGoal:()=>null,

  toolsAndStrats:"",
  setToolsAndStrats:()=>null,

  optimizeAreas:["storage"],
  setOptimizeAreas:()=>null,
  additionalArea:"",
  setAdditionalArea:()=>null,
  concerns:"",
  setConcerns:()=>null,
  preferredContactMethod:["email"],
  setPreferredContactMethod:()=>null,
  prefTime:"8am",
  setPrefTime:()=>null,

  

  numberEC2Err: "",
  setNumberEC2Err:()=>null,
  numberS3Err: "",
  setNumberS3Err:()=>null,
  otherAwsErr: "",
  setOtherAwsErr:() => null,
}

const Context = createContext(defaultValues)
const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAWSModalOpen, setIsAWSModalOpen] = useState(defaultValues.isAWSModalOpen)
  const [isLastPage, setIsLastPage] = useState(defaultValues.isLastPage)
  const [isModalOpen, setIsModalOpen] = useState(defaultValues.isModalOpen)
  const [isSuccess, setIsSuccess] = useState(defaultValues.isSuccess)
  const [firstName, setFirstName] = useState(defaultValues.firstName)
  const [lastName, setLastName] = useState(defaultValues.lastName)
  const [email, setEmail] = useState(defaultValues.email)
  const [jobTitle, setJobTitle] = useState(defaultValues.jobTitle)
  const [company, setCompany] = useState(defaultValues.company)
  const [phoneNumber, setPhoneNumber] = useState(defaultValues.phoneNumber)
  const [comment, setComment] = useState(defaultValues.comment)
  const [error, setError] = useState("")
  const [lastNameErr, setLastNameErr] = useState("")
  const [jobErr, setJobErr] = useState("")
  const [commentErr, setCommentErr] = useState("")
  const [mailErr, setMailErr] = useState("")
  const [companyNameErr, setCompanyNameErr] = useState("")
  const [phoneNumberErr, setPhoneNumberErr] = useState("")
  const [pgNum, setPgNum] = useState(defaultValues.pgNum)

  const [awsMonthly, setAwsMonthly] =useState(defaultValues.awsMonthly)
  const [numberEC2, setNumberEC2]=useState("")
  const [numberS3, setNumberS3]=useState("")
  const [otherAws, setOtherAws]=useState("")
  const [awsBsnsCrit, setAwsBsnsCrit]=useState(defaultValues.awsBsnsCrit)
  const [primaryGoal, setPrimaryGoal] =useState(defaultValues.primaryGoal)
  const [otherGoal, setOtherGoal]=useState("")
  const [toolsAndStrats, setToolsAndStrats]=useState("")
  const [optimizeAreas, setOptimizeAreas]=useState(defaultValues.optimizeAreas)
  const [additionalArea, setAdditionalArea]=useState("")
  const [concerns, setConcerns]=useState("")
  const [preferredContactMethod, setPreferredContactMethod]=useState(defaultValues.preferredContactMethod)
  const [prefTime, setPrefTime]=useState(defaultValues.prefTime)

  const[numberEC2Err, setNumberEC2Err]=useState("")
  const [numberS3Err, setNumberS3Err]=useState("")
  const [otherAwsErr, setOtherAwsErr]=useState("")
  return (
    <Context.Provider
      value={{
        isAWSModalOpen,
        setIsAWSModalOpen,
        isLastPage,
        setIsLastPage,
        isModalOpen,
        setIsModalOpen,
        isSuccess,
        setIsSuccess,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        jobTitle,
        setJobTitle,
        company,
        setCompany,
        phoneNumber,
        setPhoneNumber,
        comment,
        setComment,
        commentErr,
        setCommentErr,
        error,
        setError,
        lastNameErr,
        setLastNameErr,
        jobErr,
        setJobErr,
        mailErr,
        setMailErr,
        companyNameErr,
        setCompanyNameErr,
        phoneNumberErr,
        setPhoneNumberErr,
        pgNum,
        setPgNum,

        awsMonthly,
        setAwsMonthly,
        numberEC2,
        setNumberEC2,
        numberS3,
        setNumberS3,
        otherAws,
        setOtherAws,
        awsBsnsCrit,
        setAwsBsnsCrit,
        primaryGoal,
        setPrimaryGoal,
        otherGoal,
        setOtherGoal,
        toolsAndStrats,
        setToolsAndStrats,
        optimizeAreas,
        setOptimizeAreas,
        additionalArea,
        setAdditionalArea,
        concerns,
        setConcerns,
        preferredContactMethod,
        setPreferredContactMethod,
        prefTime, setPrefTime,
        

        numberEC2Err,
        setNumberEC2Err,
        numberS3Err,
        setNumberS3Err,
        otherAwsErr,
        setOtherAwsErr,
        
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useGeneralContext = () => useContext(Context)
export { ContextProvider, useGeneralContext }
