import { FC } from "react"
import { ReactComponent as Arrow } from "../../../images/icons/arrow.svg"
import { ReactComponent as Logo } from "../../../images/icons/logo.svg"
import { useGeneralContext } from "../../context"
import "./index.css"

const Header: FC = () => {
  const { setIsAWSModalOpen } = useGeneralContext()

  return (
    <header className={"header"}>
      <div className={"header-wrapper container"}>
        <div className={"header-logo"}>
          <Logo />
        </div>
        <div className={"connect-button-block"}>
          <button className={"connect-button"} onClick={() => setIsAWSModalOpen(true)}>
          Optimize Your AWS Costs Today
            <Arrow fill={"#3944C0"} />
          </button>
        </div>
      </div>
    </header>
  )
}

export { Header }
