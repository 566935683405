import { FC } from "react"
import { Radio, Input, Form, Checkbox } from "antd"
import { useGeneralContext } from "../../context"
import "./index.css"

const AwsInfo: FC = () => {
    const {
        awsMonthly,
        setAwsMonthly,
        numberEC2,
        setNumberEC2,
        numberEC2Err,
        otherAws,
        setOtherAws,
        awsBsnsCrit,
        setAwsBsnsCrit,
        primaryGoal,
        setPrimaryGoal,
        otherGoal,
        setOtherGoal,
        setNumberS3,
        numberS3,
        numberS3Err,
      } = useGeneralContext()
      

    return (
        <div>
          <div>
          <b>Current Monthly AWS Spend:</b>
          <Radio.Group value={awsMonthly} onChange={e => setAwsMonthly(e.target.value)}>
            <Radio value={"<$1,000"}>{"Less than $1,000"}</Radio>
            <Radio value={"$1,000 - $5,000"}>$1,000 - $5,000</Radio>
            <Radio value={"$5,000 - $10,000"}>$5,000 - $10,000</Radio>
            <Radio value={"$10,000 - $50,000"}>$1,000 - $5,000</Radio>
            <Radio value={"$50,000 - $100,000"}>$1,000 - $5,000</Radio>
            <Radio value={"$100,000+"}>More than $100,000</Radio>
          </Radio.Group>
          </div>

          <div className="form--item">
            <b>Approximate Number of EC2 Instances:</b>
            <input value={numberEC2} placeholder={""} type={"text"} onChange={e => setNumberEC2(e.target.value)}/>
            <p style={{ color: "red" }}>{numberEC2Err}</p>
          </div> 
            
          <div className="form--item">
            <b>Approximate Number of S3 Buckets:</b>
            <input value={numberS3} placeholder={""} type={"text"} onChange={e => setNumberS3(e.target.value)}/>
            <p style={{ color: "red" }}>{numberS3Err}</p>
          </div>

          <div className="form--item">
            <b>Other Significant AWS Services in Use (RDS, Lambda, etc.):</b>
            <input value={otherAws} placeholder={""} type={"text"} onChange={e => setOtherAws(e.target.value)}/>
          </div>

          <div className="form--item">
          <b>Business Criticality of AWS Services:</b>
          <Radio.Group value={awsBsnsCrit} onChange={e => setAwsBsnsCrit(e.target.value)}>
            <Radio value={"low"}>{"Low - Non-critical operations"}</Radio>
            <Radio value={"medium"}>{"Medium - Important but not mission-critical"}</Radio>
            <Radio value={"High"}>{"High - Business-critical operations"}</Radio>
          </Radio.Group>
          </div>
          
      <div className="form--item">
      <b>Primary Goal for AWS Cost Optimization:</b>
          <Checkbox.Group value={primaryGoal} onChange={e => setPrimaryGoal(e)}>
            <Checkbox value="Reduce Overall Spend">
                Reduce overall spend
            </Checkbox>
            <Checkbox value="Improve efficiency">
                Improve efficiency/performance
            </Checkbox>
            <Checkbox value="Allign Spending">
                Align spending with business objectives
            </Checkbox>
            <Checkbox value="Other">
                Other
            </Checkbox>
          </Checkbox.Group>

          <div>
            <p>Please specify additional goals:</p>
            <input className="form--item" value={otherGoal} placeholder={""} type={"text"} onChange={e => setOtherGoal(e.target.value)}/>
            <p style={{ color: "red" }}>{numberEC2Err}</p>
          </div> 
      </div>
        </div>
    )
}

export default AwsInfo;